<template>

    <div class="layout"  v-bind:class = "(loggedIn)?'layout--profile':''">

        <router-view/>

        <Footer />
        
    </div>

</template>

<style>
  @import './assets/css/style.css';
</style>

<script setup>
console.log("APP.vue: setup call");
import Footer from "@/views/main/Footer.vue";

import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const loggedIn = computed(() => store.getters["user/loggedIn"]);

</script>

