import { createRouter, createWebHistory } from 'vue-router'
import { getAuth, onAuthStateChanged } from "firebase/auth";

const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import("../views/main/Landing.vue"),
    meta: {
      title: 'VoixWare speech2subs - the first software creates subtitles of the speech in a real-time'
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () =>
      import("../views/main/Pricing.vue"),
      meta: {
        title: 'VoixWare speech2subs - pricing'
      }  
  },
  {
    path: '/subscription/success',
    name: 'subscription_success',
    component: () =>
      import("../views/billing/SubscriptionSuccess.vue"),
      meta: {
        title: 'VoixWare speech2subs - subscription has been started'
      }  
  },
  {
    path: '/subscription/error',
    name: 'subscription_error',
    component: () =>
      import("../views/billing/SubscriptionError.vue"),
      meta: {
        title: 'VoixWare speech2subs - subscription error'
      }  
  },
  {
    path: '/terms-of-service',
    name: 'terms',
    component: () =>
      import("../views/legal/Terms.vue"),
      meta: {
        title: 'VoixWare speech2subs - Terms of Service'
      }  
  },
  {
    path: '/cookie-policy',
    name: 'cookies',
    component: () =>
      import("../views/legal/Cookies.vue"),
      meta: {
        title: 'VoixWare speech2subs - Cookie Policy'
      }  
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: () =>
      import("../views/legal/Privacy.vue"),
      meta: {
        title: 'VoixWare speech2subs - Privacy Policy'
      }  
  },
  {
    path: '/eula',
    name: 'eula',
    component: () =>
      import("../views/legal/EULA.vue"),
      meta: {
        title: 'VoixWare speech2subs - End User License Agreement'
      }  
  },

  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/Login.vue"),
    meta: {
      requiresNoAuth: true,
      title: 'VoixWare speech2subs - login'
    },
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/auth/Register.vue"),
    meta: {
      requiresNoAuth: true,
      title: 'VoixWare speech2subs - registration'
    },
  },
  {
    path: "/passwordreset",
    name: "passwordreset",
    component: () =>
      import(
        /* webpackChunkName: "passwordreset" */ "../views/auth/PasswordReset.vue"
      ),
    meta: {
      requiresNoAuth: true,
      title: 'VoixWare speech2subs - reset password'
    },
  },
  {
    path: "/verify",
    name: "verify",
    component: () =>
      import(/* webpackChunkName: "verify" */ "../views/auth/Verify.vue"),
    meta: {
      requiresVerify: true,
      title: 'VoixWare speech2subs - verification'
    },
  },

  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/cabinet/Profile.vue"),
    meta: {
      requiresAuth: true,
      title: 'VoixWare speech2subs - your profile'
    },
  },
  {
    path: "/download",
    name: "download",
    component: () =>
      import("../views/cabinet/Download.vue"),
    meta: {
      requiresAuth: true,
      title: 'VoixWare speech2subs - application download'
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import("../views/cabinet/Contacts.vue"),
    meta: {
      requiresAuth: true,
      title: 'VoixWare speech2subs - contact'
    },
  },
  {
    path: "/oauth",
    name: "weblogin",
    component: () =>
      import("../views/cabinet/WebLogin.vue"),
    meta: {
      requiresAuth: true,
      title: 'VoixWare speech2subs - web login to application'
    },
  },

  {
    path: "/:catchAll(.*)",
    name: "not_found",
    component: () =>
      import("../views/main/404.vue"),
    
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});