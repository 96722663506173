import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap-icons/font/bootstrap-icons.css"
import store from './stores'

// firebase
import { initializeApp } from 'firebase/app'
import { initializeFirestore } from 'firebase/firestore'
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import { firebaseConfig } from './firebase-config'
import { getDatabase, ref, onValue } from "firebase/database";

import { getCurrentInstance } from 'vue'

console.log("main: initializeApp");
const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp);
const firestoreSettings = {
    timestampsInSnapshots: true
}
export const db = getDatabase(firebaseApp);

getAuth().onAuthStateChanged((user) => {
    // console.log("onAuthStateChanged...")
    if (user) {
        const userId = user.uid;

        onValue(ref(db, '/clients/' + userId), (snapshot) => {

            try {
                user.license = snapshot.val().license 
                user.status = snapshot.val().status 
                user.minutesUsed = snapshot.val().minutesUsed 
                user.minutesTotal = snapshot.val().minutesTotal
                user.minutesLeft = user.minutesTotal - user.minutesUsed
                user.minutesValidTill = snapshot.val().minutesValidTill
                user.nextBillingCycle = snapshot.val().nextBillingCycle
                user.nextRefreshingCycle = snapshot.val().nextRefreshingCycle
            } catch(error) {
                setTimeout(() => {  window.location.href = '/profile' }, 2000);
            }  
            // });

            store.commit("user/setUser",user);

        }, {
          onlyOnce: true
        })


    } else {
        store.commit("user/setUser",null)
    }
    console.log('---')
});

const app = createApp(App);
app.use(store)
app.use(router);
app.mount("#app");
