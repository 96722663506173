<template>

    <footer class="footer">
        <div class="wrapper">
            <div class="footer__top">
                <div class="footer__inner">
                    <div class="footer__left">
                        <div class="footer__logo">
                            <a href="https://voixware.com"><img src="@/assets/images/footer_voixware_logo.png" alt="Voice technologies for people" class="footer-logo"></a>
                        </div>
                    </div>
                    <div class="footer__center">
                        <a href="/terms-of-service"> Terms of service</a> 
                        <a href="/privacy-policy"> Privacy Policy</a> 
                        <a href="/cookie-policy"> Cookie Policy</a> 
                    </div>
                    <div class="footer__right">
                        <div class="soc-nav">
                            <ul>
                                <li class="soc-nav__item"><a href="#" class="soc-nav__link soc-nav__link--youtube"></a></li>
                                <li class="soc-nav__item"><a href="#" class="soc-nav__link soc-nav__link--twitter"></a></li>
                                <li class="soc-nav__item"><a href="#" class="soc-nav__link soc-nav__link--linkedin"></a></li>
                                <li class="soc-nav__item"><a href="#" class="soc-nav__link soc-nav__link--facebook"></a></li>
                                <li class="soc-nav__item"><a href="#" class="soc-nav__link soc-nav__link--instagram"></a></li>
                            </ul>
                        </div>
                    </div>
                </div>	
            </div>
            <div class="footer__bottom">
                <div class="footer__copyright">
                    <p>Copyright © 2022 <a href="https://voixware.com">VoixWare, LLC</a>.</p>
                    <p>VoixWare, speech2subs, Lyrebird and Impresario are Trade Marks of VoixWare, LLC</p>
                </div>
                <div class="footer__address">
                    <p>2232 Dell Range Blvd., Suite 245, Cheyenne, WY 82009</p>
                    <p>Tel: 20-VOIX-VOIX (20 8649-8649)</p>
                </div>
            </div>
        </div>
    </footer>

</template>